.solutions_block_desc {
	min-height: 160px;
}

.solutions_icons {
	margin-top: 120px;
	.item {
		margin-bottom: 60px;
	}
}

.expertise_icons {
	margin-top: 0px;
	.item {
		margin-bottom: 30px;
	}
}

.full-width--section-gradient {
	padding: 80px 0;
	background: linear-gradient(
		to left,
		var(--color-primary-gradient-startalt),
		var(--color-primary-gradient-endalt)
	);

	.btn-default {
		background: var(--color-white);
		color: var(--color-black);

		&:hover {
			background: var(--color-gray);
			color: var(--color-white);
		}
	}
}


.full-width--section-gradient-technology {
	padding: 80px 0;
	background: linear-gradient(
		to left,
		#9113EA,
		#5307A5
	);

	.btn-default {
		background: var(--color-white);
		color: var(--color-black);

		&:hover {
			background: var(--color-gray);
			color: var(--color-white);
		}
	}
}




.quality_block_content {
	h3 {
		min-height: 72px;
	}
	p {
		min-height: 200px;
	}
}

@media (max-width: 1200px) {
	.generator-tab-defalt .nav-tabs {
		overflow-x: scroll;
		overflow-y: hidden;
		padding-left: 50px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: flex-start;
	}

	.solutions_icons .specifictarget .item {
		margin-bottom: 60px;
		width: 50%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: flex-start;
		justify-content: flex-start;
		align-items: center;
		float: left;

		&:nth-child(2) {
			padding-left: 12px;
		}
	}

	.testimonial-style-defalt .inner .content .description {
		padding: 0;
		padding-top: 30px;
		margin-bottom: 30px;
		position: relative;
		min-height: 130px;
	}

	.rainbow-card .inner .thumbnail {
		overflow: hidden;
		max-height: 100px;
	}

	.service-wrapper.rainbow-service-slider-actvation.slick-grid-15.rainbow-slick-dot.rainbow-gradient-arrows.slick-initialized.slick-slider.slick-dotted {
		margin-bottom: -30px;
	}
}

.next_arrow_thingy {
	position: absolute;
	right: -10px;
	top: 30%;
}

@media (max-width: 768px) {
	.slider-bg-image.slider-bg-shape {
		position: relative;
		padding-top: 30%;
	}

	.generator-tab-defalt .nav-tabs {
		overflow-x: scroll;
		overflow-y: hidden;
		padding-left: 50px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: flex-start;
	}
	.features-section .list-style--1 i {
		margin-right: 30px;
	}

	.generator-tab-defalt .tab-pane .inner .features-section ul li {
		font-size: var(--font-size-b1);
		line-height: 19px;
		padding-left: 0;
		text-indent: -2px;
		display: flex;
		align-items: baseline;
	}

	.generator-tab-defalt .tab-pane .inner .btn-default {
		margin-bottom: 20px;
	}
	.solutions_icons .item {
		margin-bottom: 20px;
	}

	.changelog_info .changelog_date .c_date h6 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.pb--60 {
		padding-bottom: 20px !important;
	}

	.solutions_icons .specifictarget .item {
		margin-bottom: 0px;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: flex-start;
		justify-content: flex-start;
		align-items: center;
		float: left;

		.title {
			margin-top: 0 !important;
		}

		&:nth-child(2) {
			padding-left: 0px;
		}
	}

	.solutions_icons .item {
		.title {
			margin-top: 0 !important;
		}
	}

	.mb--60 {
		margin-bottom: 20px;
	}

	.solutions_block_desc {
		min-height: auto;
	}

	.generator-tab-defalt .nav-tabs {
		margin-bottom: 20px;
		padding-left: 318px;
	}

	.generator-tab-defalt .nav-link {
		width: max-content;
	}

	.blogarticlebutton {
		display: flex !important;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: flex-start;
		align-items: flex-start;
		margin-top: 20px;
	}

	.breadcrumb-area {
		padding-top: 100px !important;
		padding-bottom: 30px !important;
	}

	.target_8989 {
		font-size: 50px;
	}

	.target_738646 {
		padding-top: 0 !important;
	}

	.mnav_sub {
		margin-top: 10px !important;
		span {
			opacity: 0.7;
		}
	}
}

.popup-mobile-menu {
	z-index: 99999999;
}

.wwe_cards {
	.thumbnail {
		padding-top: 250px;
		padding-left: 60px;
		padding-right: 60px;
		padding-bottom: 40px;
	}
}

.nohoverstate {
	a {
		pointer-events: none;
	}
	.card-style-default.has-bg-shaped:hover .bg,
	.card-style-default.has-bg-shaped.active .bg {
		opacity: 1;
		visibility: visible;
	}
}

.rainbow-box-card h5.title {
	font-size: 30px;
}

.sign-up-box .signup-box-bottom .signup-box-content .social-btn-grp {
	display: flex;
	justify-content: center;
	gap: 10px;
}

@media only screen and (max-width: 479px) {
	.cd-headline {
		font-size: 40px;
	}
}

@media only screen and (max-width: 1200px) {
	.rainbow-service-area.rainbow-section-gap.rainbow-section-gapBottom-big
		h2.margin-bottom-60.title.sal-animate.d-flex.justify-content-between.capover,
	.rainbow-accordion-area.rainbow-section-gap
		h2.title.sal-animate.d-flex.justify-content-between {
		display: flex;
		flex-direction: column;

		a {
			margin-top: 40px;
			width: fit-content;
		}
	}
}

.hidenorm {
	display: none;
}

@media (min-width: 992px) and (max-width: 1400px) {
	.hide992 {
		display: none !important;
	}
	.show992 {
		display: block !important;
	}
}


@media (max-width: 768px) {
    .mb-30-mobile {
        margin-bottom: 30px;
    }
}