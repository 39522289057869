/*-- Variables --*/
:root {
	// Theme Color
	--color-primary: #;
	--color-primary-alt: #00B5D9;
	--color-secondary: #00ECD8;

	// Gredient Color
	--color-primary-gradient-start: #00ECD8;
	--color-primary-gradient-end: #00B5D9;
	--color-primary-gradient-startalt: #00ECD8;
	--color-primary-gradient-endalt: #00B5D9;

	// Typo Color
	--color-heading: #ffffff;
	--color-body: #d1d5db;
	--color-link: #ffffff;
	--color-text-off: #565e78;

	--color-dark: #131936;
	--color-darker: #131936;
	--color-darkest: #131936;
	--color-lessdark: #131936;

	--color-black: #000000;
	--color-blackest: #141b2f;
	--color-border: rgba(255, 255, 255, 0.05);
	--color-border-light: #e5e7eb;
	--color-border-dark: #1e1e1e;
	--grey-var-1: #23283c;

	// Dark-Mode
	--dark-bg-1: #f8f8ff;
	--dark-bg-2: #212330;
	--dark-bg-3: #dde7ff;
	--dark-bg-4: #ebefff;
	--dark-color-link: #55595c; // Dark Primary
	--color-border-2: #c2d4ff;
	--color-strock: #efefff;

	--dark-gradient-1: linear-gradient(
		180deg,
		rgba(122, 151, 255, 0.19) 0%,
		rgba(248, 248, 255, 0.19) 100%
	);
	--dark-gradient-2: linear-gradient(90deg, #00B5D9 0%, #00ECD8 68.75%);
	--dark-gradient-3: linear-gradient(
		93deg,
		#d3e0ff 0.78%,
		rgba(221, 231, 255, 0) 97.43%
	);

	--dark-shadow-1: 0px 14px 36.7px 0px rgba(14, 12, 21, 0.06);
	--dark-shadow-2: 0px 26px 45.4px 0px rgba(186, 193, 225, 0.72);

	//Bg Color
	--color-bg-1: #090e25; // use in Dark

	// Gery Colors
	--color-gray: #3e414b;
	--color-midgray: #878787;
	--color-light: #e4e6ea;
	--color-lighter: #ced0d4;
	--color-lightest: #f0f2f5;

	--color-white: #ffffff;
	--color-white-off: rgba(255, 255, 255, 0.05);

	// Notify Colors
	--color-success: #3eb75e;
	--color-danger: #ff0003;
	--color-warning: #ffc876;
	--color-info: #1ba2db;

	//Social icon colors
	--color-facebook: #3b5997;
	--color-twitter: #1ba1f2;
	--color-youtube: #ed4141;
	--color-linkedin: #0077b5;
	--color-pinterest: #e60022;
	--color-instagram: #c231a1;
	--color-vimeo: #00adef;
	--color-twitch: #6441a3;
	--color-discord: #7289da;

	// Extra Color
	--color-extra01: #666666;
	--color-extra02: #606770;
	--color-extra03: #fbfbfd;
	--color-extra04: #1a1a1a;
	--color-extra05: #242424;
	--color-extra06: #111424;

	//Border-radius
	--border-width: 2px;
	--radius-small: 4px;
	--radius: 12px;
	--radius-big: 16px;
	--radius-bigger: 50px;

	//Font weight
	//primary font
	--p-light: 300;
	--p-regular: 400;
	--p-medium: 500;
	--p-semi-bold: 600;
	--p-bold: 700;
	--p-extra-bold: 800;
	--p-black: 900;

	//Shadows
	--shadow-primary: 0 0 20px 5px rgba(20, 126, 243, 0.1);
	--shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
	--shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);

	//transition easing
	--transition: 0.3s;
	--transition-transform: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);

	//Font Family
	--font-primary: "Inter", sans-serif;
	--secondary-font: "Inter", sans-serif;
	--font-awesome: "Font Awesome 6 Pro";

	//Fonts Size
	--font-size-b1: 16px;
	--font-size-b2: 14px;
	--font-size-b3: 12px;

	//Line Height
	--line-height-b1: 1.67; //28px
	--line-height-b2: 1.7; //24px
	--line-height-b3: 1.6; //22px
	--line-height-b3: 1.3; //16px

	// Heading Font
	--h1: 56px; //52px 1.29
	--h2: 48px; //44px 1.23
	--h3: 30px; // 34px 1.14
	--h4: 24px; //30px  1.25
	--h5: 20px; //22px 1.24
	--h6: 18px; //20px 1.25
}

// Layouts Variation
$smlg-device: "only screen and (max-width: 1199px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";
