.inner {
    &.Collaborate-inner {
        padding: 0 50px 0 30px ;
        @media #{$sm-layout} {
            padding: 30px 0;
        }
        .description {
            @media #{$md-layout} {
                font-size: 14px;
            }
        }

    }
}


.rainbow-collobration-area{
    .collabration-image-section{
        position: relative;
        z-index: 2;
        .logo-section{
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:before {
                z-index: 0;
                content: "";
                background: linear-gradient(133deg, #ADFA4B 1.34%, #9872EA 1.35%, #4D72F5 76.16%, #FA11FF 105.35%);
                filter: blur(32.5px);
                background-size: 400%;
                position: absolute;
                top: -2px;
                left: -2px;
                z-index: -2;
                width: 102%;
                height: 102%;
                border-radius: 24px;
                animation: styles_moving-gradient 16s linear infinite;
            }
            .center-logo{
                padding: 27px 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--color-bg-1);
                border-radius: 20px;
                position: relative;
                z-index: 2;
                width: 210px;
                height: 210px;
                @media #{$md-layout} {
                    width: 100px;
                    height: 100px;
                    border-radius: var(--radius);
                    padding: 10px 15px;
                }
                @media #{$sm-layout} {
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    padding: 10px 15px;
                }
                img{
                    max-width: 90%;
                    height: auto;
                }
            }
        }
    }
}

  