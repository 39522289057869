/*----------------------
    Blog Styles  
-----------------------*/
.rainbow-card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #141b2f;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: var(--radius);
	overflow: hidden;
	transition: all 0.5s;

	.inner {
		.thumbnail {
			overflow: hidden;
			a {
				display: block;
				overflow: hidden;
				img {
					width: 100%;
					transition: 0.5s;
					overflow: hidden;
				}
			}
		}
		.content {
			display: flex;
			flex-wrap: wrap;
			padding: 30px;
			padding-top: 22px;

			@media #{$sm-layout} {
				padding: 30px 20px;
			}

			.description {
				margin-bottom: 5px;
			}

			.title {
				font-size: 20px;
				line-height: 1.5;
				transition: all 0.5s;
				margin-bottom: 5px;
				margin-top: 15px;
				flex-basis: 100%;
			}
		}
	}

	&.card-list-view {
		height: 100%;

		.inner {
			display: flex;
			height: 100%;
			@media #{$large-mobile} {
				display: block;
			}

			.thumbnail {
				flex-basis: 46%;

				a {
					display: block;
					height: 100%;

					img {
						width: auto;
						object-fit: cover;
						height: 100%;
					}
				}
			}

			.content {
				flex-basis: 54%;

				.title {
					margin-bottom: 10px;
				}

				.descriptiion {
					margin-bottom: 13px;
					margin-top: 12px;
				}
			}
		}
	}

	&.content-transparent {
		position: relative;
		border: 0 none;
		.content {
			position: absolute;
			z-index: 2;
			bottom: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0) 0,
				rgba(0, 0, 0, 0.6) 50%,
				rgba(0, 0, 0, 0.9) 100%
			);
			padding-top: 60px;

			@media #{$sm-layout} {
				padding-top: 20px;
			}

			.title {
				margin-bottom: 10px;
				@media #{$sm-layout} {
					font-size: 17px;
				}
				a {
					color: var(--color-white);
				}

				&:hover {
					a {
						color: var(--color-primary);
					}
				}
			}

			.rainbow-meta-list {
				li {
					color: #acaeb1;

					a {
						color: #acaeb1;
					}
				}
			}
		}

		&.post-large {
			.content {
				.title {
					font-size: 32px;
					@media #{$sm-layout} {
						font-size: 19px;
					}
				}
			}
		}
	}

	&:hover {
		.inner {
			.thumbnail {
				a {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}

.rainbow-meta-list {
	@extend %liststyle;
	font-size: 1.4rem;
	line-height: 2.2rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	li {
		margin-top: 0;
		margin-bottom: 0;
		color: var(--color-link);
		font-size: 14px;
		i {
			&.icon-left {
				margin-right: 5px;
				color: #16e7cf;
			}
		}

		a {
			color: var(--color-link);
			transition: 0.5s;
		}

		&.separator {
			margin: 0 0.8rem;
		}
		&.catagory-meta {
			a {
				padding: 5px 8px;
				color: var(--color-link);
				transition: 0.5s;
				&:hover {
					background: var(--color-primary);
					color: var(--color-heading);
				}
			}
		}
	}
}


// Pagination
.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1.8rem;
    --bs-pagination-color: white;
    --bs-pagination-bg: #141b2f;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #141b2f;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: #090e25;
    --bs-pagination-hover-bg: #16e7cf;
    --bs-pagination-hover-border-color: #16e7cf;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #141b2f;
    --bs-pagination-disabled-border-color: #141b2f;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.active>.page-link, .page-link.active {
	z-index: 3;
	color: #090e25;
	background-color: #16e7cf;
	border-color: #16e7cf;
}


.webinar_form_wrapper {
    background: white;
    padding: 40px;
    border-radius: 8px;
}


.video_container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video_frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}