/* Button Styles  */

.button-group {
	margin: -10px;

	a,
	button {
		margin: 10px;
	}
}
a,
button,
div {
	&.btn-default {
		padding: 0 28px;
		border-style: solid;
		border-width: 0px;
		border-color: transparent;
		border-radius: 8px;
		background-color: transparent;
		background: linear-gradient(
			to right,
			var(--color-primary-gradient-start),
			var(--color-primary-gradient-end)
		);
		transition-duration: 300ms;
		color: var(--color-white);
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		height: 50px;
		line-height: 50px;
		letter-spacing: 0.5px;
		width: auto;
		transition: 0.4s;
		@media #{$sm-layout} {
			padding: 0 22px;
		}
		&.btn-gray {
			background: #2c313f;
			color: var(--color-body);
			&:hover,
			&.active {
				background: var(--color-primary);
				color: var(--color-white);
				-webkit-filter: none;
				filter: none;
			}
		}
		&.color-blacked {
			color: var(--color-black);
			&:hover,
			&.active {
				color: var(--color-black);
			}
		}
		&.bg-shaped {
			padding: 0 32px;
			position: relative;
			background: none;
			height: 50px;
			line-height: 50px;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				background: url(/assets/images/bg/bg-shaped-btn.svg);
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 0;
				width: 100%;
				height: 100%;
			}
		}
		&.bg-solid-primary {
			background: var(--color-primary);
			&:hover {
				box-shadow: 0 0 20px 5px rgba(112, 100, 233, 0.1);
			}
		}
		&.bg-gradient-secondary {
			background: linear-gradient(
				to right,
				var(--color-secondary-gradient-start),
				var(--color-secondary-gradient-end)
			);
		}
		&.bg-gradient-tertiary {
			background: linear-gradient(
				to right,
				var(--color-tertiary-gradient-start),
				var(--color-tertiary-gradient-end)
			);
		}
		&.bg-light-gradient {
			position: relative;
			background: radial-gradient(
				85% 120% at 50% 125%,
				#ff3bd4 0.74%,
				#7130c3 65%,
				#0f1021 100%
			);
			overflow: hidden;
			transition: var(--transition);
			.has-bg-light {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in-out;
				background: radial-gradient(
					85% 142% at 50% 75%,
					#ff3bd4 0.74%,
					#7130c3 65%,
					#0f1021 100%
				);
			}
			& > span {
				position: relative;
			}
			&:hover {
				& .has-bg-light {
					opacity: 1;
					visibility: visible;
				}
				transform: translateY(-5px);
				box-shadow: 0px 24px 25px -21px #ce38cf;
			}
		}

		&.btn-icon {
			.icon {
				position: relative;
				top: 2px;
				padding-left: 4px;

				i {
					width: 20px;
					height: 20px;
				}
			}
		}

		&:hover {
			box-shadow: var(--shadow-primary);
			-webkit-filter: brightness(125%);
			filter: brightness(125%);
			color: var(--color-white);
			text-decoration: none;
		}

		&.btn-extra-small {
			height: 30px;
			line-height: 30px;
			padding: 0 8px;
			font-size: 10px;
			font-weight: 500;
		}

		&.btn-small {
			height: 40px;
			line-height: 40px;
			padding: 0 20px;
			font-size: 14px;
			font-weight: 500;
		}

		&.btn-medium {
			height: 65px;
			line-height: 65px;
			padding: 0 40px;
		}

		&.btn-large {
			height: 60px;
			line-height: 60px;
			padding: 0 37px;
			font-size: 17px;
			@media #{$sm-layout} {
				padding: 0 25px;
				height: 50px;
				line-height: 50px;
			}
		}

		&.btn-extra-large {
			height: 90px;
			line-height: 90px;
			padding: 0 70px;
			font-size: 20px;
			@media #{$sm-layout} {
				padding: 0 44px;
			}
		}

		&.btn-border {
			background-image: inherit;
			border-color: #16e7cf;
			color: var(--color-heading);
			border-width: 2px;
			border-style: solid;
			line-height: 46px;
			&.btn-small {
				line-height: 37px;
			}

			&.btn-medium {
				line-height: 61px;
			}

			&.btn-large {
				line-height: 56px;
			}

			&.btn-extra-large {
				line-height: 87px;
			}

			&:hover {
				background-color: var(--color-primary);
				border-color: var(--color-primary);
				color: var(--color-heading);
				-webkit-filter: none;
				filter: none;
			}
		}

		&.text-underline {
			background-image: inherit;
			border-color: var(--color-white);
			span {
				position: relative;
				display: inline-block;
				line-height: 30px;
			}
			&:hover {
				box-shadow: none;
				background-color: var(--color-primary);
				border-color: var(--color-primary);
			}
		}
		&.round {
			border-radius: 100px;
		}
		&.square {
			border-radius: 0;
		}
		&.rounded-player {
			border-radius: 100%;
			width: 100px;
			height: 100px;
			padding: 0;
			line-height: 100px;
			font-size: 30px;
			display: inline-block;
			@media #{$sm-layout} {
				width: 70px;
				height: 70px;
				line-height: 70px;
			}
			&.style-two {
				background: var(--color-dark);
				color: var(--color-white);
				&:hover {
					box-shadow: none;
				}
			}
			&.xs-size {
				width: 50px;
				height: 50px;
				line-height: 50px;
				font-size: 16px;
			}
			span {
				i {
					position: relative;
					top: 1px;
					left: 2px;
				}
			}
			&.border {
				background-image: inherit;
				border-color: var(--color-white) !important;
				border-width: 2px !important;
				&:hover {
					background-color: var(--color-primary);
					border-color: var(--color-primary) !important;
				}
			}
			&.sm-size {
				width: 70px;
				height: 70px;
				line-height: 70px;
			}
		}
		&.bg-white-dropshadow {
			background-color: rgba(255, 255, 255, 0.4);
			backdrop-filter: blur(7.5px);
		}
	}

	&.rainbow-gradient-btn {
		position: relative;
		display: inline-block;
		padding: 2px;
		span {
			display: inline-block;
			height: 45px;
			line-height: 45px;
			position: relative;
			z-index: 3;
			background: #0e0c15;
			padding: 0 30px;
			clip-path: polygon(
				0 0,
				calc(100% - 18px) 0,
				100% 14px,
				100% 100%,
				0 100%
			);
			border-radius: 2px;
			font-size: 14px;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}
		&::before,
		&::after {
			position: absolute;
			left: 0;
			top: 0;
			transition: all 0.3s;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to right,
				var(--color-primary-gradient-start),
				var(--color-primary-gradient-end)
			);
			content: "";
			clip-path: polygon(
				0 0,
				calc(100% - 18px) 0,
				100% 14px,
				100% 100%,
				0 100%
			);
			border-radius: 2px;
		}
		&::after {
			background: linear-gradient(
				to left,
				var(--color-primary-gradient-start),
				var(--color-primary-gradient-end)
			);
			opacity: 0;
			visibility: hidden;
		}
		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
		&.without-shape {
			border-radius: var(--radius);
			&::before,
			&::after {
				clip-path: none;
				border-radius: var(--radius);
			}
			span {
				clip-path: none;
				border-radius: var(--radius);
			}
		}
		&.without-shape-circle {
			border-radius: 100px;
			&::before,
			&::after {
				clip-path: none;
				border-radius: 100px;
			}
			span {
				clip-path: none;
				border-radius: 100px;
				background: var(--grey-var-1);
			}
		}
	}
}

.rainbow-default-tab
	.tab-button
	.tabs__tab
	.nav-link.active
	span.generator-icon {
	background: #141b2f;
}

.btn-read-more {
	span {
		position: relative;
		color: var(--color-heading);
		display: inline-block;
		line-height: 35px;
		transition: 0.3s;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			background-color: var(--color-gray);
			transition: 0.3s;
			width: 100%;
			bottom: 2px;
		}

		&::before {
			position: absolute;
			content: "";
			left: auto;
			bottom: 2px;
			background: var(--color-primary);
			width: 0;
			height: 2px;
			transition: 0.3s;
			right: 0;
			z-index: 1;
		}
		i {
			margin-left: 5px;
		}
	}

	&.border-transparent {
		span {
			&::after {
				background-color: transparent;
			}
		}
	}

	&:hover {
		span {
			color: var(--color-primary);
			&::before {
				width: 100%;
				left: 0;
				right: auto;
			}
		}
	}
}

.btn-link {
	span {
		position: relative;
		color: var(--color-heading);
		display: inline-block;
		line-height: 35px;
		transition: 0.3s;
		i {
			margin-left: 5px;
		}
	}

	&:hover {
		span {
			color: var(--color-primary);
		}
	}
}

.rainbow-badge {
	display: inline-block;
	background-color: var(--color-primary);
	padding: 0 16px;
	height: 30px;
	line-height: 30px;
	border-radius: 100px;
	color: #fff;
	font-size: 12px;
	letter-spacing: 0.5px;
	&.position-top-right {
		position: absolute;
		right: 10px;
		top: 10px;
	}
}

.rainbow-badge-card {
	background-color: transparent;
	background: linear-gradient(
		to right,
		var(--color-primary-gradient-start),
		var(--color-primary-gradient-end)
	);
	padding: 2px 8px;
	border-radius: 100px;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	display: inline-block;
	position: relative;
	z-index: 2;
	letter-spacing: 0.5px;
	line-height: 13px;
	&.badge-border {
		border: 2px solid var(--color-border);
		&::before {
			content: none;
		}
	}
	&.position-top-right {
		position: absolute;
		right: 10px;
		top: 10px;
	}
}

.btn-collaborate-play {
	i {
		height: 40px;
		width: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 500px;
		display: inline-block;
		background: linear-gradient(
				95deg,
				var(--color-primary) 15%,
				var(--color-tertiary) 45%,
				var(--color-primary) 75%,
				var(--color-primary) 100%
			)
			95% / 200% 100%;
	}
	span {
		display: inline-block;
	}
}

.btn-collaborate-play {
	height: 30px;
	width: 30px;
	line-height: 30px;
	align-items: center;
	transition: var(--transition);
	span {
		margin-left: 15px;
	}

	&:hover {
		color: var(--color-white);
	}
}

.read-more {
	.text_link {
		margin-left: 20px;
		font-size: 16px;
	}
}


// brand buttons

.btn-clients {
    background: linear-gradient(to right, #2982D3 0%, #2759D6 100%) !important;
    color: var(--color-white);
}

.btn-technology {
    background: linear-gradient(to right, #9113EA 0%, #5307A5 100%) !important;
    color: var(--color-white);
}

.btn-people {
    background: linear-gradient(to right, #FFBC2D 0%, #FF5E35 100%) !important;
    color: var(--color-white);
}

.btn-transparent {
    background: transparent !important;
    color: var(--color-white);
}

.btn-border-clients {
	background-image: inherit;
	background: transparent !important;
	border-color: #2982D3 !important; 
	color: var(--color-white);
	border-width: 2px !important;
	border-style: solid !important;
	line-height: 46px !important;
	&.btn-small {
		line-height: 37px !important;
	}

	&.btn-medium {
		line-height: 61px;
	}

	&.btn-large {
		line-height: 56px;
	}

	&.btn-extra-large {
		line-height: 87px;
	}

	&:hover {
		background-color: var(--color-primary);
		border-color: var(--color-primary);
		color: var(--color-heading);
		-webkit-filter: none;
		filter: none;
	}
}

.clients-gradient-btn {
	position: relative;
	display: inline-block;
	padding: 2px;
	span {
		display: inline-block;
		height: 45px;
		line-height: 45px;
		position: relative;
		z-index: 3;
		background: #0e0c15;
		padding: 0 30px;
		clip-path: polygon(
			0 0,
			calc(100% - 18px) 0,
			100% 14px,
			100% 100%,
			0 100%
		);
		border-radius: 2px;
		font-size: 14px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
	}
	&::before,
	&::after {
		position: absolute;
		left: 0;
		top: 0;
		transition: all 0.3s;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			to right,
			#2982D3, #2759D6
		);
		content: "";
		clip-path: polygon(
			0 0,
			calc(100% - 18px) 0,
			100% 14px,
			100% 100%,
			0 100%
		);
		border-radius: 2px;
	}
	&::after {
		background: linear-gradient(
			to left,
			#2982D3, #2759D6
		);
		opacity: 0;
		visibility: hidden;
	}
	&:hover::after {
		opacity: 1;
		visibility: visible;
	}
	&.without-shape {
		border-radius: var(--radius);
		&::before,
		&::after {
			clip-path: none;
			border-radius: var(--radius);
		}
		span {
			clip-path: none;
			border-radius: var(--radius);
		}
	}
	&.without-shape-circle {
		border-radius: 100px;
		&::before,
		&::after {
			clip-path: none;
			border-radius: 100px;
		}
		span {
			clip-path: none;
			border-radius: 100px;
			background: var(--grey-var-1);
		}
	}
}