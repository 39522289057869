/*=========================
    Section Separation 
==========================*/

.mt_dec--30 {
	margin-top: -30px;
}
.mt_dec--40 {
	margin-top: -40px;
}

.mt_dec--10 {
	margin-top: -10px;
}

.mb---120 {
	margin-bottom: -120px;
}

.mb---70 {
	margin-bottom: -70px;
}

.rainbow-section-gap {
	padding: 70px 0 !important;
	@media #{$md-layout} {
		padding: 40px 0 !important;
	}
	@media #{$sm-layout} {
		padding: 30px 0 !important;
	}
}
.rainbow-section-gap-big {
	padding: 140px 0 !important;
	@media #{$md-layout} {
		padding: 80px 0 !important;
	}
	@media #{$sm-layout} {
		padding: 60px 0 !important;
	}
}

.rainbow-section-gapBottom {
	padding-bottom: 60px !important;
	@media #{$md-layout} {
		padding-bottom: 40px !important;
	}
	@media #{$sm-layout} {
		padding-bottom: 30px !important;
	}
}
.rainbow-section-gapBottom-big {
	padding-bottom: 120px !important;
	@media #{$md-layout} {
		padding-bottom: 80px !important;
	}
	@media #{$sm-layout} {
		padding-bottom: 60px !important;
	}
}

.pb--165,
.pb--85 {
	@media #{$md-layout} {
		padding-bottom: 80px !important;
	}
	@media #{$sm-layout} {
		padding-bottom: 60px !important;
	}
}

.rainbow-section-gapTop {
	padding-top: 60px !important;
	@media #{$md-layout} {
		padding-top: 40px !important;
	}
	@media #{$sm-layout} {
		padding-top: 30px !important;
	}
}
.rainbow-section-gapTop-big {
	padding-top: 120px !important;
	@media #{$md-layout} {
		padding-top: 80px !important;
	}
	@media #{$sm-layout} {
		padding-top: 60px !important;
	}
}

.ptb--120 {
	@media #{$md-layout} {
		padding: 80px 0 !important;
	}
	@media #{$sm-layout} {
		padding: 60px 0 !important;
	}
}

.pb--165,
.pb--120,
.pb--85,
.pb--165 {
	@media #{$md-layout} {
		padding-bottom: 80px !important;
	}
	@media #{$sm-layout} {
		padding-bottom: 60px !important;
	}
}

.pb--70 {
	@media #{$sm-layout} {
		padding-bottom: 60px !important;
	}
}

.pl--0 {
	padding-left: 0 !important;
}
.pr--0 {
	padding-right: 0 !important;
}
.pt--0 {
	padding-top: 0 !important;
}
.pb--0 {
	padding-bottom: 0 !important;
}
.mr--0 {
	margin-right: 0 !important;
}
.ml--0 {
	margin-left: 0 !important;
}
.mt--0 {
	margin-top: 0 !important;
}
.mb--0 {
	margin-bottom: 0 !important;
}

.pt--260 {
	padding-top: 260px !important;
}

.pt--250 {
	padding-top: 250px !important;
}

.pb--240 {
	padding-bottom: 240px !important;
}

.pb--220 {
	padding-bottom: 220px !important;
}

.plr--270 {
	padding: 0 270px !important;
}

.plr--300 {
	padding: 0 300px !important;
}

.plr--340 {
	padding: 0 340px !important;
}

.ptb--300 {
	padding: 300px 0 !important;
}

.ptb--340 {
	padding: 340px 0 !important;
}

.ptb--450 {
	padding: 450px 0 !important;
}

.plr_dec--15 {
	margin: 0 -15px !important;
}

.pt--300 {
	padding-top: 300px !important;
}

@for $i from 1 through 40 {
	.ptb--#{5 * $i} {
		padding: 5px * $i 0 !important;
	}
	.plr--#{5 * $i} {
		padding: 0 5px * $i !important;
	}
	.pt--#{5 * $i} {
		padding-top: 5px * $i !important;
	}
	.pb--#{5 * $i} {
		padding-bottom: 5px * $i !important;
	}
	.pl--#{5 * $i} {
		padding-left: 5px * $i !important;
	}
	.pr--#{5 * $i} {
		padding-right: 5px * $i !important;
	}
	.mt--#{5 * $i} {
		margin-top: 5px * $i !important;
	}
	.mb--#{5 * $i} {
		margin-bottom: 5px * $i;
	}
	.mr--#{5 * $i} {
		margin-right: 5px * $i !important;
	}
	.ml--#{5 * $i} {
		margin-left: 5px * $i !important;
	}
}

@media #{$laptop-device} {
	@for $i from 1 through 20 {
		.ptb_lp--#{5 * $i} {
			padding: 5px * $i 0 !important;
		}

		.plr_lp--#{5 * $i} {
			padding: 0 5px * $i !important;
		}

		.pt_lp--#{5 * $i} {
			padding-top: 5px * $i !important;
		}

		.pb_lp--#{5 * $i} {
			padding-bottom: 5px * $i !important;
		}

		.pl_lp--#{5 * $i} {
			padding-left: 5px * $i !important;
		}

		.pr_lp--#{5 * $i} {
			padding-right: 5px * $i !important;
		}

		.mt_lp--#{5 * $i} {
			margin-top: 5px * $i !important;
		}

		.mb_lp--#{5 * $i} {
			margin-bottom: 5px * $i !important;
		}
	}
}

@media #{$lg-layout} {
	@for $i from 1 through 20 {
		.ptb_lg--#{5 * $i} {
			padding: 5px * $i 0 !important;
		}
		.plr_lg--#{5 * $i} {
			padding: 0 5px * $i !important;
		}
		.pt_lg--#{5 * $i} {
			padding-top: 5px * $i !important;
		}
		.pb_lg--#{5 * $i} {
			padding-bottom: 5px * $i !important;
		}

		.pl_lg--#{5 * $i} {
			padding-left: 5px * $i !important;
		}

		.pr_lg--#{5 * $i} {
			padding-right: 5px * $i !important;
		}

		.mt_lg--#{5 * $i} {
			margin-top: 5px * $i !important;
		}

		.mb_lg--#{5 * $i} {
			margin-bottom: 5px * $i !important;
		}
		.ml_lg--#{5 * $i} {
			margin-left: 5px * $i !important;
		}
	}
}

@media #{$md-layout} {
	.ptb_md--0 {
		padding: 0 !important;
	}
	.pl_md--0 {
		padding-left: 0 !important;
	}
	.pr_md--0 {
		padding-right: 0 !important;
	}
	.pt_md--0 {
		padding-top: 0 !important;
	}
	.pb_md--0 {
		padding-bottom: 0 !important;
	}
	.mr_md--0 {
		margin-right: 0 !important;
	}
	.ml_md--0 {
		margin-left: 0 !important;
	}
	.mt_md--0 {
		margin-top: 0 !important;
	}
	.mb_md--0 {
		margin-bottom: 0 !important;
	}
	.ptb_md--250 {
		padding: 250px 0 !important;
	}

	@for $i from 1 through 20 {
		.ptb_md--#{5 * $i} {
			padding: 5px * $i 0 !important;
		}

		.plr_md--#{5 * $i} {
			padding: 0 5px * $i !important;
		}

		.pt_md--#{5 * $i} {
			padding-top: 5px * $i !important;
		}

		.pb_md--#{5 * $i} {
			padding-bottom: 5px * $i !important;
		}

		.pl_md--#{5 * $i} {
			padding-left: 5px * $i !important;
		}

		.pr_md--#{5 * $i} {
			padding-right: 5px * $i !important;
		}

		.mt_md--#{5 * $i} {
			margin-top: 5px * $i !important;
		}

		.mb_md--#{5 * $i} {
			margin-bottom: 5px * $i !important;
		}
	}
}

@media #{$sm-layout} {
	.ptb_sm--250 {
		padding: 250px 0 !important;
	}
	.ptb_sm--0 {
		padding: 0 !important;
	}
	.pl_sm--0 {
		padding-left: 0 !important;
	}
	.pr_sm--0 {
		padding-right: 0 !important;
	}
	.pt_sm--0 {
		padding-top: 0 !important;
	}
	.pb_sm--0 {
		padding-bottom: 0 !important;
	}
	.mr_sm--0 {
		margin-right: 0 !important;
	}
	.ml_sm--0 {
		margin-left: 0 !important;
	}
	.mt_sm--0 {
		margin-top: 0 !important;
	}
	.mb_sm--0 {
		margin-bottom: 0 !important;
	}
	.pt_sm--150 {
		padding-top: 150px !important;
	}
	.pb_sm--110 {
		padding-bottom: 110px !important;
	}
	@for $i from 1 through 20 {
		.ptb_sm--#{5 * $i} {
			padding: 5px * $i 0 !important;
		}
		.plr_sm--#{5 * $i} {
			padding: 0 5px * $i !important;
		}
		.pt_sm--#{5 * $i} {
			padding-top: 5px * $i !important;
		}

		.pb_sm--#{5 * $i} {
			padding-bottom: 5px * $i !important;
		}

		.pl_sm--#{5 * $i} {
			padding-left: 5px * $i !important;
		}

		.pr_sm--#{5 * $i} {
			padding-right: 5px * $i !important;
		}

		.mt_sm--#{5 * $i} {
			margin-top: 5px * $i !important;
		}

		.ml_sm--#{5 * $i} {
			margin-left: 5px * $i !important;
		}

		.mr_sm--#{5 * $i} {
			margin-right: 5px * $i !important;
		}

		.mb_sm--#{5 * $i} {
			margin-bottom: 5px * $i !important;
		}
	}

	.pl_sm--0 {
		padding-left: 0 !important;
	}
	.pr_sm--0 {
		padding-right: 0 !important;
	}
	.pt_sm--0 {
		padding-top: 0 !important;
	}
	.pb_sm--0 {
		padding-bottom: 0 !important;
	}
	.mr_sm--0 {
		margin-right: 0 !important;
	}
	.ml_sm--0 {
		margin-left: 0 !important;
	}
	.mt_sm--0 {
		margin-top: 0 !important;
	}
	.mb_sm--0 {
		margin-bottom: 0 !important;
	}
}

@media #{$large-mobile} {
	@for $i from 1 through 20 {
		.ptb_mobile--#{5 * $i} {
			padding: 5px * $i 0 !important;
		}
		.plr_mobile--#{5 * $i} {
			padding: 0 5px * $i !important;
		}
		.pt_mobile--#{5 * $i} {
			padding-top: 5px * $i !important;
		}
		.pb_mobile--#{5 * $i} {
			padding-bottom: 5px * $i !important;
		}
		.pl_mobile--#{5 * $i} {
			padding-left: 5px * $i !important;
		}
		.pr_mobile--#{5 * $i} {
			padding-right: 5px * $i !important;
		}
		.mt_mobile--#{5 * $i} {
			margin-top: 5px * $i !important;
		}
		.mb_mobile--#{5 * $i} {
			margin-bottom: 5px * $i !important;
		}
	}
}

@for $i from 1 through 20 {
	.slick-gutter-#{$i * 5} {
		margin-left: -#{$i * 5}px !important;
		margin-right: -#{$i * 5}px !important;

		.slick-slide {
			padding-left: #{$i * 5}px !important;
			padding-right: #{$i * 5}px !important;
		}
	}
}

.max-width-160 {
	max-width: 160px;
}

.collaborate-plr-60 {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.margin-top-5 {
	margin-top: 5px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-top-20 {
	margin-top: 20px !important;
}

.margin-top-30 {
	margin-top: 30px !important;
}

.margin-top-40 {
	margin-top: 40px !important;
}

.margin-top-60 {
	margin-top: 60px !important;
}

.margin-top-80 {
	margin-top: 80px !important;
}

.margin-top-100 {
	margin-top: 100px !important;
}

.margin-top-120 {
	margin-top: 120px !important;
}

.margin-bottom-0 {
	margin-bottom: 0px !important;
}

.margin-bottom-5 {
	margin-bottom: 5px !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px !important;
}

.margin-bottom-40 {
	margin-bottom: 40px !important;
}

.margin-bottom-60 {
	margin-bottom: 60px !important;
}

.margin-bottom-80 {
	margin-bottom: 80px !important;
}

.margin-bottom-120 {
	margin-bottom: 120px !important;
}

.margin-left-5 {
	margin-left: 5px !important;
}

.margin-left-10 {
	margin-left: 10px !important;
}

.margin-left-20 {
	margin-left: 20px !important;
}

.margin-left-30 {
	margin-left: 30px !important;
}

.margin-left-40 {
	margin-left: 40px !important;
}

.margin-right-10 {
	margin-right: 10px !important;
}

.margin-right-20 {
	margin-right: 20px !important;
}

.line-height-14 {
	line-height: 1.4 !important;
}

.split-lp {
    padding: 0px 45px;
}

.expertise-card-heights {
	min-height: 465px;
}

.blog-card-heights {
	height: 365px;
}

.social-icon {
	margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.social-icon-top {
	border-top: 1px solid var(--color-border);
	margin-top: 36px !important;
	padding-top: 36px !important;
    margin-bottom: 12px !important;
}

.value-divider {
	border-top: 1px solid var(--color-border);
	margin-top: 36px !important;
	padding-top: 22px !important;
    margin-bottom: 12px !important;
}